import styled from "styled-components";

import { breakpoints } from "utils/mixins";
import { white } from "../../theme/colors";

export const BannerContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  * {
    outline: none !important;
  }
  div {
    height: calc(100vh - 68px);
  }
  .slick-arrow {
    color: transparent;
    height: 81px;
    width: 19px;
    z-index: 1;
    background: url("/images/arrow_left.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    &.slick-next {
      background: url("/images/arrow_right.png");
      background-repeat: no-repeat;
      background-size: contain;
      left: unset;
      right: 15px;
    }
  }
  .slick-dots {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex !important;
    li {
      button {
        margin: 5px;
        content: "";
        background-color: ${white.dark};
        opacity: 0.7;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        color: transparent;
      }
      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }
  @media ${breakpoints.laptop} {
    div {
      height: calc(100vh - 144px);
    }
    .slick-arrow {
      height: 116px;
      width: 27px;
    }
    .slick-dots {
      li {
        button {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  img {
    height: 100% !important;
    width: 100%;
    /* object-fit: contain!important; */
    object-position: center center !important;
  }
  .info {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: calc(50% - 72px);
    left: 0;
    padding: 0 25px;
    transform: translate(0, -50%);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: white;
    }
    h3 {
      display: inline;
      color: white;
      padding: 5px;
      font-weight: 300;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  .button_area {
    position: absolute;
    z-index: 1;
    bottom: 60px;
    left: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateX(-50%);
    height: auto;
    div {
      height: auto;
      button {
        min-width: 200px;
      }
    }
  }
  @media ${breakpoints.laptop} {
    .info {
      top: calc(50% - 72px);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .button_area {
      bottom: 60px;
    }
  }
`;
