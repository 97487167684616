import React, { useState } from "react";

import { Link } from "gatsby";
import Img from "gatsby-image";
import HTMLReactParser from "html-react-parser";

import { black, white } from "theme/colors";
import Banner from "components/banner";
import Button from "components/button";
import Contacts from "components/contacts";
import Services from "components/services";

const DefaultHomepage = (props) => {
  //PROPS
  const {
    data: {
      allWpPost: { edges },
      page: {
        services,
        contactos,
        homepage: {
          formEmailServicos,
          formEmailContacto,
          slide,
          whoTitle,
          whoSections,
          whoText,
          whoImage,
          finTitle,
          finText,
          finIcon,
          finImage,
          insTitle,
          insText,
          aceTitle,
          aceColumns,
          aceImage
        }
      }
    }
  } = props;
  //PROPS

  function compare(a, b) {
    if (a.node.uri < b.node.uri) {
      return -1;
    }
    if (a.node.uri > b.node.uri) {
      return 1;
    }
    return 0;
  }

  // console.log(compare(edges));

  //USESTATES
  const [about, setAbout] = useState(0);
  //USESTATES

  return (
    <>
      <main className="main main--nopad">
        <Banner data={slide} />
        <section className="section" id="onde-estamos">
          <h2 className="pad-top">
            <b>Concessionários</b>
          </h2>
          <div className="container section-conce">
            {edges.sort(compare).map((conce, index) => {
              return (
                <div className="section-conce-thumb" key={index}>
                  <div className="section-conce-thumb-image">
                    <Img
                      alt={conce.node.concessionarios?.fachada?.altText}
                      fluid={
                        conce.node.concessionarios?.fachada?.localFile
                          ?.childImageSharp?.fluid
                      }
                    />
                    <ul className="section-conce-thumb-brands">
                      {conce.node.concessionarios.marcasThumbnail.map(
                        (logo, index) => {
                          return (
                            <li key={index}>
                              <img
                                src={logo?.localFile.url}
                                alt="Logo Moto Spazio"
                              />
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                  <div className="section-conce-thumb-cta">
                    <p>
                      {conce.node.title}{" "}
                      {conce.node.uri === "/viseu/" ? (
                        <span>(Oficina)</span>
                      ) : null}
                    </p>
                    <Link to={`concessionario-${conce.node.uri.slice(1)}`}>
                      ENTRAR{" "}
                      <img src="/images/arrow.svg" alt="Arrow para Entrar" />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <section className="section" id="quem-somos">
          <div className="section-banner">
            <Img
              fluid={whoImage?.localFile?.childImageSharp?.fluid}
              alt={whoImage?.altText}
            />
            <h2>{whoTitle}</h2>
          </div>

          <div className="container section-content">
            {whoText && HTMLReactParser(whoText)}

            <ul className="section-content-menu">
              {whoSections.map((section, index) => {
                return (
                  <li className={about === index ? "active" : ""} key={index}>
                    <button onClick={() => setAbout(index)}>
                      {section.title}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="section-content-texts">
              <div className="section-content-texts-reference">
                {whoSections[2].text && HTMLReactParser(whoSections[2].text)}
              </div>

              {whoSections.map((section, index) => {
                return (
                  <div
                    key={index}
                    className={
                      about === index
                        ? "section-content-text"
                        : "section-content-text section-content-text--hidden"
                    }
                  >
                    {section.text && HTMLReactParser(section.text)}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <Services
          data={services}
          formEmailServicos={formEmailServicos}
          place="Geral"
        />

        <section className="section section--unlimited" id="financiamento">
          <div className="section-banner">
            <Img
              fluid={finImage.localFile.childImageSharp.fluid}
              alt={finImage.altText}
            />
            <h2>{finTitle}</h2>
          </div>
          <div className="container section-content">
            <img
              className="section-content-icon"
              src={finIcon.localFile.url}
              alt={finIcon.altText}
            />
            <div>{finText && HTMLReactParser(finText)}</div>
          </div>
        </section>
        <section className="section section--unlimited" id="acessorios">
          <div className="section-banner">
            <Img
              fluid={aceImage.localFile.childImageSharp.fluid}
              alt={aceImage.altText}
            />
            <h2>{aceTitle}</h2>
          </div>
          <div className="container section-content section-content--columns">
            {aceColumns.map((column, index) => {
              return (
                <div className="section-content-column" key={index}>
                  <img
                    className="section-content-column-logo"
                    src={column.logo?.localFile?.url}
                    alt={column.logo?.altText}
                  />
                  <Img
                    className="section-content-column-photo"
                    fluid={column.image.localFile.childImageSharp.fluid}
                    alt={column.image.altText}
                  />
                  <div className="section-content-column-text">
                    {column.text && HTMLReactParser(column.text)}
                  </div>
                  <a href={column.linkPdf.url} target="_blank" rel="noreferrer">
                    <Button
                      text="VER CATÁLOGO"
                      backgroundColor={white.default}
                      scolor={black}
                      isBordered={true}
                      isBordBlack={true}
                      minWidth
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </section>
        <Contacts
          data={contactos}
          formEmailContacto={formEmailContacto}
          place="Geral"
          title={props.location.state?.title}
        />
        <section className="section container">
          <h2 className="pad-top">{insTitle}</h2>
          <div style={{ paddingBottom: "20px" }}>
            {insText && HTMLReactParser(insText)}
          </div>
          <div className="elfsight-app-fa615324-b3ce-4a16-a9ed-85a7f77b865c"></div>
        </section>
      </main>
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      contactos {
        addMap
        contAddress
        contLatitude
        contLongitude
        contTextEmail
        contTextSchedule
        contTitle
        contEmail {
          email
        }
        contPhones {
          phone
        }
      }
      services {
        serviceFormId
        serviceTitleForm
        serviceIcon {
          altText
          localFile {
            url
          }
        }
        serviceTitle
        serviceText
        serviceImagem {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      homepage {
        formEmailContacto
        formEmailServicos
        aceTitle
        aceColumns {
          text
          logo {
            altText
            localFile {
              url
            }
          }
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          linkPdf {
            url
          }
        }
        aceImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        finTitle
        finText
        finIcon {
          altText
          localFile {
            url
          }
        }
        finImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        insTitle
        insText
        whoTitle
        whoText
        whoSections {
          title
          text
        }
        whoImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        slide {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
          buttons {
            buttonAction
            buttonColor
            buttonStyle
            label
            link {
              target
              title
              url
            }
          }
        }
      }
    }
    allWpPost {
      edges {
        node {
          concessionarios {
            fachada {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            marcasThumbnail {
              altText
              localFile {
                url
              }
            }
          }
          title
          uri
        }
      }
    }
  }
`;

export default DefaultHomepage;
