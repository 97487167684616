import React, { useRef } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";

import { BannerContainer, Slide } from "./Banner.styles";
import Button from "../button/Button";
import { white } from "theme/colors";
import { navigate } from "gatsby";

const Banner = (props) => {
  const { data } = props;

  //REF
  const slider = useRef(null);
  //REF

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    draggable: true,
    adaptiveHeight: false,
    slidesToShow: 1,
    swipe: true,
    fade: false,
    slidesToScroll: 1,
    swipeToSlide: false,
    // autoplay: true,
    autoplaySpeed: 2000,
    afterChange: (e) => {
      //setDisabled(false);
    },
    beforeChange: (e) => {
      //setDisabled(true);
    }
  };

  return (
    <BannerContainer>
      <Slider {...settings} ref={slider}>
        {data.map((slide, index) => {
          const image = slide.image.localFile.childImageSharp.fluid;

          return (
            <Slide key={index}>
              <Img fluid={image} alt={slide.image.altText} />
              <div className="info">
                <h2>{slide.title}</h2>
                <h3>{slide.subtitle}</h3>
              </div>
              <div className="button_area">
                {slide.buttons.map((button, indexBtn) => {
                  return (
                    <div key={indexBtn}>
                      <Button
                        text={
                          button.buttonAction === "Go to form"
                            ? button.label
                            : button.link.title
                        }
                        color={white.default}
                        backgroundColor={
                          button.buttonStyle === "Color"
                            ? button.buttonColor
                            : null
                        }
                        isLink={button.buttonAction !== "Go to form"}
                        onclick={
                          button.buttonAction === "Go to form"
                            ? () =>
                                navigate("/#cform", {
                                  state: { title: slide.title }
                                })
                            : button.link.url
                        }
                        isBordered={
                          button.buttonStyle === "Border" ? true : false
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Slide>
          );
        })}
      </Slider>
    </BannerContainer>
  );
};

export default Banner;
